import React from "react";
import MenuAppBar from './components/MenuAppBar'
import VerticalSlider from "./components/VerticalSlider";

const App = () => {
  return (
    <>
      <MenuAppBar />
      <div style={{marginTop: '5em'}}>
        <VerticalSlider />
      </div>
    </>
  )
};

export default App;
