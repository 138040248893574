import React, { useState } from "react";
import Slider from "react-slick";
import "../../node_modules/slick-carousel/slick/slick.css";
import "../../node_modules/slick-carousel/slick/slick-theme.css";
import { ListItem, CircularProgress } from "@material-ui/core";
import Post from './Post'
import { useAllPostsQuery } from "../generated/graphql";

const VerticalSlider = () => {
  const fetchAmount = 5
  const [amountFetched, setAmountFetched] = useState(5)
  const { data, fetchMore, networkStatus } = useAllPostsQuery({
    variables: { first: fetchAmount },
    notifyOnNetworkStatusChange: true
  })

  if (!data) return <CircularProgress />

  const settings = {
    arrows: false,
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    beforeChange: function(currentSlide: number, nextSlide: number) {},
    afterChange: function(currentSlide: number) {
      if (currentSlide === amountFetched - 1) {
        setAmountFetched(amountFetched + fetchAmount)
        fetchMore({
          variables: {
            first: fetchAmount,
            cursor: data.postsConnection.pageInfo.endCursor
          },
          updateQuery: (pv:any, { fetchMoreResult }: any) => {
            if (!fetchMoreResult) {
              return pv;
            }
            return {
              postsConnection: {
                __typename: 'PostConnection',
                pageInfo: fetchMoreResult.postsConnection.pageInfo,
                edges: [
                  ...pv.postsConnection.edges,
                  ...fetchMoreResult.postsConnection.edges
                ],
              }
            };
          }
        })
      }
    }
  }


  return (
    <div style={{ maxWidth: 400, margin: "auto", padding: 10 }}>
      <Slider {...settings}>
        {data.postsConnection.edges?.map((x:any, i:number) => (
          <ListItem key={x.node.id}>
            <Post />
          </ListItem>
      ))}
      </Slider>
      {networkStatus === 3 && <CircularProgress />}
    </div>
  )
}

export default VerticalSlider
