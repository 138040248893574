import ApolloClient from "apollo-boost";
import React from "react";
import { ApolloProvider } from "react-apollo-hooks";
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks'
import ReactDOM from "react-dom";
import App from "./App";
import './index.css'

const client = new ApolloClient({ uri: "http://9c500b6c.ngrok.io/graphql" });

ReactDOM.render(
  <ApolloProvider client={client}>
    <ApolloHooksProvider client={client}>
      <App />
    </ApolloHooksProvider>
  </ApolloProvider>,
  document.getElementById("root")
);
