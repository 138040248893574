import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHoc from '@apollo/react-hoc';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
};

export type Mutation = {
   __typename?: 'Mutation',
  testField: Scalars['String'],
};

export type PageInfo = {
   __typename?: 'PageInfo',
  endCursor?: Maybe<Scalars['String']>,
  hasNextPage: Scalars['Boolean'],
  hasPreviousPage: Scalars['Boolean'],
  startCursor?: Maybe<Scalars['String']>,
};

export type Post = {
   __typename?: 'Post',
  id: Scalars['ID'],
  title: Scalars['String'],
  url: Scalars['String'],
};

export type PostConnection = {
   __typename?: 'PostConnection',
  edges?: Maybe<Array<Maybe<PostEdge>>>,
  nodes?: Maybe<Array<Maybe<Post>>>,
  pageInfo: PageInfo,
};

export type PostEdge = {
   __typename?: 'PostEdge',
  cursor: Scalars['String'],
  node?: Maybe<Post>,
};

export type Query = {
   __typename?: 'Query',
  posts: Array<Post>,
  postsConnection: PostConnection,
};


export type QueryPostsConnectionArgs = {
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};

export type AllPostsQueryVariables = {
  cursor?: Maybe<Scalars['String']>,
  first: Scalars['Int']
};


export type AllPostsQuery = (
  { __typename?: 'Query' }
  & { postsConnection: (
    { __typename?: 'PostConnection' }
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
    ), edges: Maybe<Array<Maybe<(
      { __typename?: 'PostEdge' }
      & Pick<PostEdge, 'cursor'>
      & { node: Maybe<(
        { __typename?: 'Post' }
        & Pick<Post, 'id' | 'title' | 'url'>
      )> }
    )>>> }
  ) }
);


export const AllPostsDocument = gql`
    query AllPosts($cursor: String, $first: Int!) {
  postsConnection(after: $cursor, first: $first) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        id
        title
        url
      }
    }
  }
}
    `;
export type AllPostsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AllPostsQuery, AllPostsQueryVariables>, 'query'> & ({ variables: AllPostsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AllPostsComponent = (props: AllPostsComponentProps) => (
      <ApolloReactComponents.Query<AllPostsQuery, AllPostsQueryVariables> query={AllPostsDocument} {...props} />
    );
    
export type AllPostsProps<TChildProps = {}> = ApolloReactHoc.DataProps<AllPostsQuery, AllPostsQueryVariables> & TChildProps;
export function withAllPosts<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AllPostsQuery,
  AllPostsQueryVariables,
  AllPostsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, AllPostsQuery, AllPostsQueryVariables, AllPostsProps<TChildProps>>(AllPostsDocument, {
      alias: 'allPosts',
      ...operationOptions
    });
};

/**
 * __useAllPostsQuery__
 *
 * To run a query within a React component, call `useAllPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPostsQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useAllPostsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllPostsQuery, AllPostsQueryVariables>) {
        return ApolloReactHooks.useQuery<AllPostsQuery, AllPostsQueryVariables>(AllPostsDocument, baseOptions);
      }
export function useAllPostsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllPostsQuery, AllPostsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AllPostsQuery, AllPostsQueryVariables>(AllPostsDocument, baseOptions);
        }
export type AllPostsQueryHookResult = ReturnType<typeof useAllPostsQuery>;
export type AllPostsLazyQueryHookResult = ReturnType<typeof useAllPostsLazyQuery>;
export type AllPostsQueryResult = ApolloReactCommon.QueryResult<AllPostsQuery, AllPostsQueryVariables>;