import React from 'react'
import { AppBar, Toolbar, IconButton, Typography, makeStyles, createStyles, Theme } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  }),
);

const MenuAppBar = () => {
  const classes = useStyles()

  return (
    <AppBar position="fixed">
      <Toolbar>
        <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" className={classes.title}>
          Scroller
        </Typography>
      </Toolbar>
    </AppBar>
  )
}

export default MenuAppBar
